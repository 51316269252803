import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo } from "apollo-angular";
import { Observable, retry, shareReplay } from "rxjs";
import { GQLService } from "../../core";
import { GET_FREQUENTLY_ASKED_QUESTIONS, GET_FREQUENTLY_ASKED_QUESTIONS_BY_TAGS } from "./query/frequently-asked-question-query.gql";

@Injectable({
  providedIn: "root"
})
export class FrequentlyAskedQuestionGql extends GQLService {
  constructor(protected override apollo: Apollo) {
    super(apollo);
  }

  findAll(): Observable<ApolloQueryResult<any>> {
    return this.watchQuery(GET_FREQUENTLY_ASKED_QUESTIONS)
      .valueChanges.pipe(retry({count:3,delay:3000}));
  }

  findAllByTags(tags: string[]): Observable<ApolloQueryResult<any>> {
    return this.watchQuery(GET_FREQUENTLY_ASKED_QUESTIONS_BY_TAGS, { tags })
      .valueChanges.pipe(retry({count:3,delay:3000}));
  }
}