import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageAccountLoginComponent } from './page-account-login.component';

const routes: Routes = [{ path: '', component: PageAccountLoginComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageAccountLoginRoutingModule { }
