import { gql } from "@apollo/client/core";

export const GET_ARTICLE_BY_SLUG = gql`
query GetArticleBySlug($slug: String!) {
  articles(filters:{ Slug:{eq:$slug} } ) {
    data{
      attributes{
        createdAt
        Title
        EstimatedReadTime
        EstimatedReadTimeMetric
        Slug
        Author{
            data{
                attributes{
                    firstName
                    lastName
                    bio
                    facebook
                    instagram
                    linkedin
                    email
                    avatar{
                            data{
                                attributes{
                                    url
                                }
                            }
                    }
                }
            }
        }   
        Thumbnail{
            data{
                attributes{
                    url
                }
            }
        }
        Categories{
            data{
                attributes{
                    Description
                    Name
                    Slug
                }
            }
        }
        Elements{
            __typename

            ... on ComponentElementParagraph{
                Identifier
                Text
            }
            ... on ComponentElementStepGroup{
                Title
                Summary
                Steps{
                    Image {
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                    Text
                }
            }

            ... on ComponentElementList{
                Title
                Items{
                    Text
                }
            }

            ... on ComponentElementInlineAlert{
                Icon
                Route
                RouteLabel
                Text
            }

            ... on ComponentElementParagraphGroup{
                            Identifier
                            Title
                            Paragraphs{
                                Identifier
                                Text
                            }
                    }   
                    
            ... on ComponentElementList{
                        ListItems: Items {
                            Text
                            Title
                        }
                    }

            ... on ComponentElementParagraphGroup{
                    Identifier
                    Title
                    Paragraphs{
                        Identifier
                        Text
                    }
            }

            ... on ComponentElementHeader {
                Identifier
                Title
                SubTitle
                EmphasisWord
                EmphasisColor
            }             

            ... on ComponentElementLink{
                Identifier
                Label
                Route
                SubLabel
                Tooltip
            }     

                    ... on ComponentSharedSeo{
                        canonicalURL
                        keywords
                        metaDescription
                        metaImage{
                            data{
                                attributes{
                                    url
                                    name
                                    alternativeText
                                }
                            }
                        }
                        metaRobots
                        metaSocial{
                            description
                            image{
                                data{
                                    attributes{
                                        url
                                        name
                                        alternativeText
                                    }
                                }
                            }
                            socialNetwork
                            title
                        }
                        metaTitle
                        metaViewport
                        structuredData
                    }        


        }                 
      }
    }
  }
}
`;

export const GET_ARTICLE_BY_CATEGORY = gql`
query GetArticleByCategory($slug: String!) {
    articles(filters:{ Categories:{ Slug:{eq:$slug}} } ) {
    data{
      attributes{
        createdAt
        Title
        EstimatedReadTime
        EstimatedReadTimeMetric
        Slug
        Author{
            data{
                attributes{
                    firstName
                    lastName
                    avatar{
                            data{
                                attributes{
                                    url
                                }
                            }
                    }
                }
            }
        }         
        Thumbnail{
            data{
                attributes{
                    url
                }
            }
        }
        Categories{
            data{
                attributes{
                    Description
                    Name
                    Slug
                }
            }
        }
        Elements{
            __typename

            ... on ComponentElementParagraph{
                Identifier
                Text
            }
            ... on ComponentElementStepGroup{
                Title
                Summary
                Steps{
                    Image {
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                    Text
                }
            }

            ... on ComponentElementList{
                Title
                Items{
                    Text
                }
            }

            ... on ComponentElementInlineAlert{
                Icon
                Route
                RouteLabel
                Text
            }


            ... on ComponentElementParagraphGroup{
                    Identifier
                    Title
                    Paragraphs{
                        Identifier
                        Text
                    }
            }

            ... on ComponentElementHeader {
                Identifier
                Title
                SubTitle
                EmphasisWord
                EmphasisColor
            }                    


        }                 
      }
    }
  }
}
`;

export const GET_ARTICLES = gql`
query GetArticles {
  articles{
        data{
            attributes{
                createdAt
        Title
        EstimatedReadTime
                EstimatedReadTimeMetric
                Slug
                Author{
            data{
                attributes{
                    firstName
                    lastName
                    avatar{
                            data{
                                attributes{
                                    url
                                }
                            }
                    }
                }
            }
        }                 
                Thumbnail{
                    data{
                        attributes{
                            url
                        }
                    }
                }
                Categories{
                    data{
                        attributes{
                            Description
                            Name
                            Slug
                        }
                    }
                }                
            }
        }
    }
}
`;