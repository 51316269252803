import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentListComponent } from './component-list.component';
import { PipeModule } from 'src/app/pipe/pipe.module';

@NgModule({
  declarations: [
    ComponentListComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    ComponentListComponent
  ]
})
export class ComponentListModule { }
