import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRoutingModule } from './page-routing.module';
import { PageAccountActivateModule } from './page-account-activate/page-account-activate.module';
import { PageAccountLoginModule } from './page-account-login/page-account-login.module';
import { PageAccountLoginOtpModule } from './page-account-login-otp/page-account-login-otp.module';
import { PageAccountModule } from './page-account/page-account.module';
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    PageRoutingModule,
    PageAccountActivateModule,
    PageAccountLoginModule,
    PageAccountLoginOtpModule,
    PageAccountModule
  ]
})
export class PageModule { }
