// vas-deal-api.service.ts

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../core';

const TIMEOUT = 20000
const DEBIT_ORDER_API_KEY = 'Basic dGVjaHN1cmVkZmlyc3R2aWV3OlQhZWNoMTVmdiM='
const OCP_APIM_SUBSCRIPTION_KEY = ''

@Injectable()
export class VasSignUpApiService extends ApiService {
  constructor(
    protected override readonly httpClient: HttpClient
  ) {
    super(httpClient, "/api/vas");
  }

  createDebitOrder(_provider: string, _payload: any): Observable<any> {
    const apiUrl = `https://vas.api.ignitiongroup.co.za/vas/${_provider}/CreateDebitOrder`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': DEBIT_ORDER_API_KEY,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': '*'
    });

    return this.httpClient.post(apiUrl, _payload, { headers });
  }
}
