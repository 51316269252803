
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo, QueryRef, TypedDocumentNode } from "apollo-angular";
import { EmptyObject } from "apollo-angular/types";
import { DocumentNode } from "graphql";
import { Observable, Subscription } from "rxjs";

export class GQLService{
    
    protected subscription:Subscription | undefined;

    constructor(protected readonly apollo: Apollo) {}
    
    watchQuery(query:DocumentNode | TypedDocumentNode<unknown, EmptyObject>, variables?:any): QueryRef<unknown, EmptyObject>{
        return this.apollo.watchQuery({
            query:query,
            variables:variables
        })
    }

    query(query:DocumentNode | TypedDocumentNode<unknown, EmptyObject>, variables?:any): Observable<ApolloQueryResult<unknown>>{
        return this.apollo.watchQuery({
            query:query,
            variables:variables
        }).valueChanges
    }
}