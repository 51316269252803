import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSubmitClaimModule } from './modal-submit-claim/modal-submit-claim.module';
import { ModalPersonalInformationModule } from './modal-personal-information/modal-personal-information.module';
import { ModalBillingInformationModule } from './modal-billing-information/modal-billing-information.module';
import { ModalDevicesListModule } from './modal-devices-list/modal-devices-list.module';
import { ModalHomeDealsModule } from './modal-home-deals/modal-home-deals.module';
import { ModaCallMeBackModule } from './modal-call-me-back/modal-call-me-back.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ModalSubmitClaimModule,
    ModalPersonalInformationModule,
    ModalBillingInformationModule,
    ModalDevicesListModule,
    ModaCallMeBackModule,
    ModalHomeDealsModule,
  ]
})
export class ModalModule { }
