import { Component, OnInit } from '@angular/core';
import { Page } from 'ngx-techsured';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalCallMeBackComponent } from "src/app/modal/modal-call-me-back/modal-call-me-back.component";


@Component({
  selector: 'app-page-account-login',
  templateUrl: './page-account-login.component.html',
  styleUrls: ['./page-account-login.component.scss']
})
export class PageAccountLoginComponent extends Page implements OnInit {

  constructor(_dialogService: DialogService) {
    super({ dialogService: _dialogService })
  }

  ngOnInit(): void {
  }

  onCallMeBackClick(){
    const modalInst = this.openModal(ModalCallMeBackComponent, {
      data: {},
      styleClass: "modal-bg-dark modal-sm",
      modal: true
  })
  }

}
