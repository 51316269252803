import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageAccountLoginOtpRoutingModule } from './page-account-login-otp-routing.module';
import { PageAccountLoginOtpComponent } from './page-account-login-otp.component';
import { LayoutPageAccountModule } from 'src/app/layout/layout-page-account/layout-page-account.module';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DirectiveModule } from 'src/app/directive/directive.module';


@NgModule({
  declarations: [
    PageAccountLoginOtpComponent
  ],
  imports: [
    CommonModule,
    PageAccountLoginOtpRoutingModule,
    LayoutPageAccountModule,
    InputTextModule,
    ButtonModule,
    DirectiveModule
  ]
})
export class PageAccountLoginOtpModule { }
