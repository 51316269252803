import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch:"full", loadChildren: () => import('./page-home/page-home.module').then(m => m.PageHomeModule) },
  { path: 'how-it-works', loadChildren: () => import('./page-how-it-work/page-how-it-work.module').then(m => m.PageHowItWorkModule) },
  { path: 'contact-us', loadChildren: () => import('./page-contact-us/page-contact-us.module').then(m => m.PageContactUsModule) },
  { path: 'about-us', loadChildren: () => import('./page-about-us/page-about-us.module').then(m => m.PageAboutUsModule) },
  { path: 'privacy-policy', loadChildren: () => import('./page-privacy-policy/page-privacy-policy.module').then(m => m.PagePrivacyPolicyModule) },
  { path: 'terms-and-conditions', loadChildren: () => import('./page-term-and-condition/page-term-and-condition.module').then(m => m.PageTermAndConditionModule) },
  { path: 'paia', loadChildren: () => import('./page-paia/page-paia.module').then(m => m.PagePaiaModule) },
  { path: 'manual-disclosure', loadChildren: () => import('./page-manual-disclosure/page-manual-disclosure.module').then(m => m.PageManualDisclosureModule) },

  { path: 'get-a-quote', loadChildren: () => import('./page-get-a-quote/page-get-a-quote.module').then(m => m.PageGetAQuoteModule) },
  { path: 'get-a-quote/:product-slug', loadChildren: () => import('./page-get-a-quote/page-get-a-quote.module').then(m => m.PageGetAQuoteModule) },
  { path: 'get-a-quote/:product-slug/:product-specific', loadChildren: () => import('./page-get-a-quote/page-get-a-quote.module').then(m => m.PageGetAQuoteModule) },
  { path: 'frequently-asked-question', loadChildren: () => import('./page-frequently-asked-question/page-frequently-asked-question.module').then(m => m.PageFrequentlyAskedQuestionModule) },

  // NEW GET A QUOTE HOME
  { path: 'get-a-quote-home', loadChildren: () => import('./page-get-a-quote-home/page-get-a-quote.module').then(m => m.PageGetAQuoteModule) },
  { path: 'get-a-quote-home/:option-slug', loadChildren: () => import('./page-get-a-quote-home/page-get-a-quote.module').then(m => m.PageGetAQuoteModule) },

  //account
  { path: 'account', loadChildren: () => import('./page-account/page-account.module').then(m => m.PageAccountModule) },
  { path: 'account/activate', loadChildren: () => import('./page-account-activate/page-account-activate.module').then(m => m.PageAccountActivateModule) },
  { path: 'account/login', loadChildren: () => import('./page-account-login/page-account-login.module').then(m => m.PageAccountLoginModule) },
  { path: 'account/login/otp', loadChildren: () => import('./page-account-login-otp/page-account-login-otp.module').then(m => m.PageAccountLoginOtpModule) },

  { path: 'device-insurance', loadChildren: () => import('./page-device-insurance/page-device-insurance.module').then(m => m.PageDeviceInsuranceModule) },
  { path: 'device-insurance/laptop-insurance', loadChildren: () => import('./page-laptop-insurance/page-laptop-insurance.module').then(m => m.PageLaptopInsuranceModule) },
  { path: 'device-insurance/tablet-insurance', loadChildren: () => import('./page-tablet-insurance/page-tablet-insurance.module').then(m => m.PageTabletInsuranceModule) },
  { path: 'device-insurance/wearable-insurance', loadChildren: () => import('./page-wearable-insurance/page-wearable-insurance.module').then(m => m.PageWearableInsuranceModule) },
  { path: 'device-insurance/decoder-insurance', loadChildren: () => import('./page-decoder-insurance/page-decoder-insurance.module').then(m => m.PageDecoderInsuranceModule) },
  { path: 'device-insurance/cellphone-insurance', loadChildren: () => import('./page-cellphone-insurance/page-cellphone-insurance.module').then(m => m.PageCellphoneInsuranceModule) },
  { path: 'device-insurance/screen-protect', loadChildren: () => import('./page-screen-protect/page-screen-protect.module').then(m => m.PageScreenProtectModule) },
  { path: 'device-insurance/home-device-insurance', loadChildren: () => import('./page-home-device-insurance/page-home-device-insurance.module').then(m => m.PageHomeDeviceInsuranceModule) },


  { path: 'intellicell', loadChildren: () => import('./page-intellicell/page-intellicell.module').then(m => m.PageIntellicellModule) },
  { path: 'best4u', loadChildren: () => import('./page-best4u/page-best.module').then(m => m.PageBestModule) },
  

  { path: 'resources', pathMatch:"full", loadChildren: () => import('./page-blog/page-blog.module').then(m => m.PageBlogModule) },
  { path: 'resources/article/:slug', pathMatch:"full", loadChildren: () => import('./page-blog-read/page-blog-read.module').then(m => m.PageBlogReadModule) },
  { path: 'disclosure-notice', loadChildren: () => import('./page-disclosure-notice/page-disclosure-notice.module').then(m => m.PageDisclosureNoticeModule) },
  { path: 'tcf-policy', loadChildren: () => import('./page-tcf-policy/page-tcf-policy.module').then(m => m.PageTcfPolicyModule) },
  { path: 'complaints', loadChildren: () => import('./page-complaints/page-complaints.module').then(m => m.PageComplaintsModule) },
  { path: 'cfm-policy', loadChildren: () => import('./page-cfm-policy/page-cfm-policy.module').then(m => m.PageCfmPolicyModule) },
  { path: 'disclaimer', loadChildren: () => import('./page-disclaimer/page-disclaimer.module').then(m => m.PageDisclaimerModule) },

  // //Landing 
  { path: 'lp/cell', loadChildren: () => import('./page-landing/page-landing.module').then(m => m.PageLandingModule) },
  { path: 'lp/cell/:slug', loadChildren: () => import('./page-landing/page-landing.module').then(m => m.PageLandingModule) },
  // params page
  { path: 'eeb-bmw', loadChildren: () => import('./page-eeb-bmw/page-quick-quote.module').then(m => m.PageQuickQuoteModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageRoutingModule { }
