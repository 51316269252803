import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetArticleCarouselComponent } from './widget-article-carousel.component';
import { ChipModule } from 'primeng/chip';
import { CarouselModule } from 'primeng/carousel';
import { AvatarModule } from 'primeng/avatar';
import { RouterModule } from '@angular/router';
import { ComponentImageModule } from 'src/app/component/component-image/component-image.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    WidgetArticleCarouselComponent
  ],
  imports: [
    CommonModule,
    ChipModule,
    CarouselModule,
    AvatarModule,
    RouterModule,
    ComponentImageModule,
    PipeModule,
    ButtonModule
  ],
  exports: [
    WidgetArticleCarouselComponent
  ],providers:[
    
  ]
})
export class WidgetArticleCarouselModule { }
