import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentCallMeBackComponent } from './component-call-me-back.component';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import { EnergyLeadApiService } from 'ngx-techsured';
import {CheckboxModule} from 'primeng/checkbox';
@NgModule({
  declarations: [
    ComponentCallMeBackComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    CheckboxModule
  ],
  exports: [
    ComponentCallMeBackComponent
  ],
  providers:[
    EnergyLeadApiService
  ]
})
export class ComponentCallMeBackModule { }