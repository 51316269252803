import { HttpClient } from '@angular/common/http';
import { Observable, retry, shareReplay } from 'rxjs';

export class ApiService {

    protected originalPath: string;

    constructor(protected readonly httpClient: HttpClient, protected path: string) {
        this.originalPath = path;
    }

    public exists(options?: any, cache: boolean = false): Observable<any> {
        return this.get(`${this.path}/exists`, options, cache);
    }
    public find(id: number | string, options?: any, cache: boolean = false): Observable<any> {
        return this.get(`${this.path}/${id}`, options, cache);
    }
    public findAll(options?: any, cache: boolean = false): Observable<any> {
        return this.get(this.path, options, cache);
    }
    public create(data: any, options?: any): Observable<any> {
        return this.post(this.path, data, options)
    }
    public update(id: number | string, data: any, options?: any, patch: boolean = false): Observable<any> {
        console.debug("update called ", patch);

        if (patch === true) {
            console.debug("update via patch verb")
            return this.patch(`${this.path}/${id}`, data, options);
        }
        return this.put(`${this.path}/${id}`, data, options);
    }
    public remove(id: number | string, options?: any): Observable<any> {
        return this.delete(`${this.path}/${id}`, options)
    }

    protected get(url: string, options?: any, cache: boolean = false): Observable<any> {
        // let cacheKey: string = Md5.init(url);
        console.debug("processing request url: ", url, " options: ", options);
        return this.httpClient.get(url, options).pipe(retry({ count: 3, delay: 3000 }));
    }

    protected post(url: string, data: any, options?: any): Observable<any> {
        console.debug("processing request url: ", url, "data: ", data, " options: ", options);
        return this.httpClient.post(url, data, options).pipe(retry({ count: 3, delay: 3000 }));
    }

    protected put<T>(url: string, data: any, options?: any): Observable<any> {
        console.debug("processing request url: ", url, "data: ", data, " options: ", options);
        return this.httpClient.put<T>(url, data, options).pipe(retry({ count: 3, delay: 3000 }));
    }

    protected patch(url: string, data: any, options?: any): Observable<any> {
        console.debug("processing request url: ", url, "data: ", data, " options: ", options);
        return this.httpClient.patch(url, data, options).pipe(retry({ count: 3, delay: 3000 }));
    }

    protected delete(url: string, options?: any): Observable<any> {
        console.debug("processing request url: ", url, " options: ", options);
        return this.httpClient.delete(url, options).pipe(retry({ count: 3, delay: 3000 }));
    }
}