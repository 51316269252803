import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { EnergyLeadApiService } from 'ngx-techsured';
import { Router } from '@angular/router';
import { FormPrepopulateService } from 'src/app/service/form-prepop.services';
@Component({
    selector: 'app-modal-call-me-back',
    templateUrl: './modal-call-me-back.component.html',
    styleUrls: ['./modal-call-me-back.component.scss']
})
export class ModalCallMeBackComponent implements OnInit {

    devices: any = []

    constructor(
        public dynamicDialogRef: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private formPrepopulateService: FormPrepopulateService,
        private _formBuilder: FormBuilder,
        private readonly _energyLeadApiService: EnergyLeadApiService,
        private router: Router
    ) { }

    decodedUd: any;
    callMeData: any = {
        'FirstName': '',
        'Surname': '',
        'MobileNumber': '',
        'EmailAddress': ''
    }

    validations: any = {
        'invalidFirstName': false,
        'invalidSurname': false,
        'invalidMobileNumber': false,
        'invalidEmailAddress': false
    }

    showAgentWillContactYou: boolean = false
    callMeBackConsent: boolean = false
    currentUrl: any = ''

    ngOnInit(): void {
        this.currentUrl = this.router.url;
        this.devices = this.dynamicDialogConfig.data;
        this.loadLocalData();
    
        const decodedUd = this.formPrepopulateService.getDecodedUd();
        if (decodedUd) {
            this.populateFormFields(decodedUd);
        }
    }
    
    populateFormFields(decodedUd: any): void {
        if (decodedUd.mobileNumber) {
            let mobile = decodedUd.mobileNumber;
            if (mobile.startsWith('27')) {
                mobile = '0' + mobile.substring(2);
            } else if (mobile.startsWith('+27')) {
                mobile = '0' + mobile.substring(3);
            }
            this.callMeData = {
                FirstName: decodedUd.firstName,
                Surname: decodedUd.lastName,
                EmailAddress: decodedUd.email,
                MobileNumber: mobile,
                IdNumber: decodedUd.idNumber
            };
        }
    }

    loadLocalData(): void {
        let localData: any = localStorage.getItem("call-me-back-data");
        if (localData != null) {
            localData = JSON.parse(localData);
            console.log('there is local data');
            this.callMeData['FirstName'] = localData['Name'];
            this.callMeData['Surname'] = localData['Surname'];
            this.callMeData['MobileNumber'] = localData['Mobile'];
            this.callMeData['EmailAddress'] = localData['Email'];
        }
    }

    isValidPayload(_payload: any) {
        if (this.callMeData.FirstName.length < 1) { this.validations.invalidFirstName = true; return false; } else { this.validations.invalidFirstName = false; }
        if (this.callMeData.Surname.length < 1) { this.validations.invalidSurname = true; return false; } else { this.validations.invalidSurname = false; }
        if (this.callMeData.MobileNumber.length != 10) { this.validations.invalidMobileNumber = true; return false; } else { this.validations.invalidMobileNumber = false; }
        // if(this.callMeData.EmailAddress.length < 10){this.validations.invalidEmailAddress=true;return false;}else{this.validations.invalidEmailAddress=false;}
        return true;
    }

    onCallMeBackClick() {
        // debugger
        let payload = this.callMeData
        if (this.currentUrl.includes("intellicell")) {
            payload['Referred'] = 'intellicell'
        }

        if (!this.isValidPayload(payload)) { return; };
        try {
            this.showAgentWillContactYou = true
            this._energyLeadApiService.callMeBack(payload).subscribe({
                next: (_response) => {
                    this.showAgentWillContactYou = false
                    this.close()
                },
                error: (error: any) => {
                    console.error("failed to obtain devices ", error);
                    this.showAgentWillContactYou = false
                    this.close()
                }
            });
        } catch (error) { }
    }

    close() {
        setTimeout(() => {
            this.dynamicDialogRef.close({});
        }, 2000);
    }
}
