<layout-page-account>
  
          <div class="row justify-content-center justify-content-xl-end mb-5 mb-lg-0">
            <div class="col-12 col-md-9 col-lg-9 col-xl-12 text-center text-xl-end">
              <a role="button" (click)="onCallMeBackClick()"
              class="text-white fs-7 d-inline-flex text-decoration-none align-items-center ms-xl-auto mt-2 me-2">Need help? <span class="ms-2 fw-bold">Call me back</span> <i class="pi pi-fw pi-angle-right fs-5 pb-xxl-1"></i></a>
            </div>
          </div>
          <div class="row justify-content-center my-auto">
            <div class="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-6">
              <h2 class="fs-2d text-white fw-bold mb-4">Check your <span class="text-secondary">email</span> for your OTP</h2>
              <div class="my-4">
                <input type="number" class="w-100 fs-7 py-3" placeholder="OTP" pInputText />
              </div>
  
              <button type="submit" pButton class="btn btn-primary w-100 px-4 mb-5" label="VERIFY & LOGIN" routerLink="/account"></button>
  
              <div class="text-center text-lg-start">
                <a role="button" class="text-white opacity-62 text-decoration-none fw-bold" routerLink="/">Back to login</a>
              </div>
            </div>
          </div>
  
</layout-page-account>