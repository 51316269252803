import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageAccountLoginRoutingModule } from './page-account-login-routing.module';
import { PageAccountLoginComponent } from './page-account-login.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { LayoutPageAccountModule } from 'src/app/layout/layout-page-account/layout-page-account.module';
import { DirectiveModule } from 'src/app/directive/directive.module';


@NgModule({
  declarations: [
    PageAccountLoginComponent
  ],
  imports: [
    CommonModule,
    PageAccountLoginRoutingModule,
    InputTextModule,
    ButtonModule,
    LayoutPageAccountModule,
    DirectiveModule
  ]
})
export class PageAccountLoginModule { }
