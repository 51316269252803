<h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">Chat to a  <span class="text-secondary">consultant</span></h2>

<div class="px-4">
    <div class="row justify-content-center">
        <div class="col-12 mb-3">
            <input [(ngModel)]="callMeData.FirstName" type="text" class="w-100" placeholder="First Name" aria-label="First Name" pInputText />
            <p *ngIf="validations.invalidFirstName" class="text-start text-danger">Please Provide Name</p>
        </div>
        <div class="col-12 mb-3">
            <input [(ngModel)]="callMeData.Surname" type="text" class="w-100" placeholder="Surname" aria-label="Surname" pInputText />
            <p *ngIf="validations.invalidSurname" class="text-start text-danger">Please Provide Name</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 mb-3">
            <input [(ngModel)]="callMeData.MobileNumber" type="tel" class="w-100" placeholder="Mobile Number" aria-label="Mobile Number" pInputText />
            <p *ngIf="validations.invalidMobileNumber" class="text-start text-danger">Please Provide Valid Mobile Number</p>
        </div>
        <div class="col-12 mb-3">
            <input [(ngModel)]="callMeData.EmailAddress" type="email" class="w-100" placeholder="Email address" aria-label="Email address" pInputText />
            <!-- <p *ngIf="validations.invalidEmailAddress" class="text-start text-info opacity-60">Please Provide Valid Email</p> -->
        </div>
    </div>
    <div class="row mb-3">
        <style>
            .my-chb-style{
                height: 20px;
                width: 20px;
            }
        </style>
        <div class="col-sm-12">
            <div class="form-check d-flex align-items-start">
                <input [(ngModel)]="callMeBackConsent" class="my-chb-style form-control form-check-input mt-1" type="checkbox" id="call-me-back-consent">
                <label class="form-check-label w-100 text-white" for="call-me-back-consent">
                    &nbsp; &nbsp;I consent to my personal information being used by Viva Cover to contact me and market insurance products that may be suitable to my needs.
                    My information will be stored safely as per Viva Cover’s privacy policy.</label>
                </div>
        </div>
    </div>
    <div class="row justify-content-center mt-4 pt-lg-3">
        <div class="col-12 col-md-6 col-lg-auto">
            <button [disabled]="!callMeBackConsent"  (click)="onCallMeBackClick()" pButton class="btn btn-primary" label="Call me back"></button>
            <p *ngIf="showAgentWillContactYou" class="text-danger mt-2">An agent will be in contact shortly</p>
        </div>
    </div>
</div>