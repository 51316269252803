import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSubmitClaimComponent } from './modal-submit-claim.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [
    ModalSubmitClaimComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule 
  ],
  exports: [
    ModalSubmitClaimComponent
  ]
})
export class ModalSubmitClaimModule { }
