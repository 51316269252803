<!-- <p-dialog [(visible)]="displayPersonalEditModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">-->

<h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">Edit <span class="text-secondary">Profile</span></h2>

<div class="px-4">
    <div class="row gx-3 gy-4">
        <!-- <div class="col-12 col-md-4">
            <p-dropdown [options]="titleOptions" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Title"
                optionLabel="option" [(ngModel)]="selectedTitle"></p-dropdown>
        </div> -->
        <div class="col-12">
            <input [(ngModel)]="personalInfo.FirstName" type="text" [class]="validFormControl('FirstName')" class="w-100 fs-7" placeholder="First Name" pInputText value="John" />
        </div>
        <div class="col-12">
            <input [(ngModel)]="personalInfo.LastName" type="text" [class]="validFormControl('LastName')" class="w-100 fs-7" placeholder="Surname" pInputText value="Doe" />
        </div>
        <div class="col-12">
            <input [(ngModel)]="personalInfo.IdNumber" type="number" [class]="validFormControl('IdNumber')" class="w-100 fs-7" placeholder="RSA ID Number" pInputText value="9105025145085" />
        </div>
        <div class="col-12">
            <input [(ngModel)]="personalInfo.MSISDN" type="tel" [class]="validFormControl('MSISDN')" class="w-100 fs-7" placeholder="Mobile Number" pInputText value="076 969 2245" />
        </div>
        <div class="col-12">
            <input [(ngModel)]="personalInfo.EmailAddress" type="email" [class]="validFormControl('EmailAddress')" class="w-100 fs-7" placeholder="Email address" pInputText value="john@email.com" />
        </div>
        <div *ngIf="personalInfo.RiskAddress.length > 1" class="col-12">
            <input [(ngModel)]="personalInfo.RiskAddress" type="text" class="w-100 fs-7" [class]="validFormControl('RiskAddress')" placeholder="Home address" pInputText value="12 main street" />
        </div>
    </div>
</div>
<div class="d-flex flex-column flex-sm-row justify-content-sm-center align-items-sm-center px-4 px-sm-0 gap-3 pb-2 pt-4 mb-2">
    <div *ngIf="callResponseMsg.show" role="alert" class="alert alert-notice fw-bold text-red text-center">
        {{callResponseMsg.message}}
    </div> 
    <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Update" (click)="close()"></button>

</div>