import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo, gql } from "apollo-angular";
import { Observable, retry, retryWhen, shareReplay, delay } from "rxjs";
import { GQLService } from "../../core";
import { GET_LANDING_PAGE_AND_ELEMENTS_BY_SLUG } from "./query/landing-page-query.gql";

@Injectable({
  providedIn: "root"
})
export class LandingPageGql extends GQLService{
  constructor(protected override apollo: Apollo) { 
    super(apollo);
  }

  // findProvidersBySlug(provider: string): Observable<ApolloQueryResult<any>> {
  //    return this.apollo.watchQuery<any>({
  //     query: gql`
  //     query {
  //         landing_pages( Providers: { eq: "${provider}" }}){
  //           data {
  //             attributes{
  //               Name
  //               Slug
  //             }
  //           }
  //         }
  //       }
  // `
// }).valueChanges.pipe(retry({count:3,delay:3000}));
// }

  findBySlug(slug: string): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: gql`
            query {
               landingPages(filters: { Slug: { eq: "${slug}" }}){
                  data {
                    attributes{
                      Title
                      Path
                    }
                  }
                }
              }
        `
    }).valueChanges.pipe(retry({count:3,delay:3000}));
  }

  findBySlugWithElements(slug: string): Observable<ApolloQueryResult<any>> {
    return this.watchQuery(GET_LANDING_PAGE_AND_ELEMENTS_BY_SLUG,{slug})
    .valueChanges.pipe(retry({count:3,delay:3000}));
  }  
}