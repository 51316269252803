import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentHeroModule } from './component-hero/component-hero.module';
import { ComponentNavigationBarModule } from './component-navigation-bar/component-navigation-bar.module';
import { ComponentFooterModule } from './component-footer/component-footer.module';
import { ComponentPageSectionModule } from './component-page-section/component-page-section.module';
import { ComponentIconGroupModule } from './component-icon-group/component-icon-group.module';
import { ComponentProductCardModule } from './component-product-card/component-product-card.module';
import { ComponentStepGroupModule } from './component-step-group/component-step-group.module';
import { ComponentParagraphGroupModule } from './component-paragraph-group/component-paragraph-group.module';
import { ComponentInlineAlertModule } from './component-inline-alert/component-inline-alert.module';
import { ComponentListModule } from './component-list/component-list.module';
import { ComponentAuthorBadgeModule } from './component-author-badge/component-author-badge.module';
import { ComponentImageModule } from './component-image/component-image.module';
import { ComponentHeaderModule } from './component-header/component-header.module';
import { ComponentProductCarouselModule } from './component-product-carousel/component-product-carousel.module';
import { ComponentHdCarouselModule } from './component-hd-carousel/component-hd-carousel.module';
import { ComponentProductGridModule } from './component-product-grid/component-product-grid.module';
import { ComponentIntellicellNavModule } from './component-intellicell-nav/component-intellicell-nav.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ComponentHeroModule,
    ComponentNavigationBarModule,
    ComponentFooterModule,
    ComponentPageSectionModule,
    ComponentIconGroupModule,
    ComponentProductCardModule,
    ComponentStepGroupModule,
    ComponentParagraphGroupModule,
    ComponentInlineAlertModule,
    ComponentListModule,
    ComponentAuthorBadgeModule,
    ComponentImageModule,
    ComponentHeaderModule,
    ComponentProductCarouselModule,
    ComponentHdCarouselModule,
    ComponentProductGridModule,
    ComponentIntellicellNavModule
    ]
})
export class ComponentModule { }
