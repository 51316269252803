import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentProductCardComponent } from './component-product-card.component';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { ButtonModule } from 'primeng/button';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    ComponentProductCardComponent
  ],
  imports: [
    CommonModule,
    DirectiveModule,
    ButtonModule,
    PipeModule,
    RouterModule
  ],
  exports: [
    ComponentProductCardComponent
  ]
})
export class ComponentProductCardModule { }
