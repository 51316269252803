import * as _ from "lodash";
import { environment } from "src/environments/environment";
export class Widget {

    busy: boolean = false;

    constructor() { }

    protected init(data: any) {

    }

    //FIXME: convert to pipe, current function call has performance issue
    asset(url: string): string {
        return url;
    }
    assetSet(items: any[], setKey: string, ratio: string = "2x"): string | undefined {
        const set: string[] = items.map((item) => {
            return this.asset(_.get(item, setKey));
        });
        console.debug("assetSet items ", items, " set ", set);
        return (_.isEmpty(set)) ? undefined : `${set.join(", ")} ${ratio}`;
    }
}