import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_FLAGS, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { createPersistedQueryLink } from "apollo-angular/persisted-queries";
import { sha256 } from "crypto-hash";
import { environment } from 'src/environments/environment';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return  {
    link: createPersistedQueryLink({ sha256 }).concat(httpLink.create({ 
        uri: `${environment.API_HOST}/graphql`, 
        headers: new HttpHeaders()
          .append(
            "Authorization",
            `Bearer ${environment.API_TOKEN}`
          )
     })),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [
    ApolloModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true,
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class AppGraphQLModule { }
