import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetProductCardGroupComponent } from './widget-product-card-group.component';



@NgModule({
  declarations: [
    WidgetProductCardGroupComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WidgetProductCardGroupComponent
  ]
})
export class WidgetProductCardGroupModule { }
