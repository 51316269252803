import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageAccountActivateRoutingModule } from './page-account-activate-routing.module';
import { PageAccountActivateComponent } from './page-account-activate.component';
import { DirectiveModule } from 'src/app/directive/directive.module';


@NgModule({
  declarations: [
    PageAccountActivateComponent
  ],
  imports: [
    CommonModule,
    PageAccountActivateRoutingModule,
    DirectiveModule
  ]
})
export class PageAccountActivateModule { }
