import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmphasisLastWordDirective } from './emphasis-last-word.directive';
import { EmphasisWordDirective } from './emphasis-word.directive';
import { HeaderDirective } from './header.directive';
import { SafeImageDirective } from './safe-image-src.directive';
import { DirectiveImageSrcDirective } from './directive-image-src.directive';
@NgModule({
  declarations: [
    EmphasisLastWordDirective,
    EmphasisWordDirective,
    HeaderDirective,
    DirectiveImageSrcDirective,
    SafeImageDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EmphasisLastWordDirective,
    EmphasisWordDirective,
    HeaderDirective,
    DirectiveImageSrcDirective,
    SafeImageDirective
  ]
})
export class DirectiveModule { }
