import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalCallMeBackComponent } from './modal-call-me-back.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { ComponentCallMeBackModule } from 'src/app/component/component-call-me-back/component-call-me-back.module';



@NgModule({
    declarations: [
        ModalCallMeBackComponent
    ],
    imports: [
        CommonModule,
        DropdownModule,
        ReactiveFormsModule,
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        ButtonModule,
        ComponentCallMeBackModule
    ],
    exports: [
        ModalCallMeBackComponent
    ]
})
export class ModaCallMeBackModule { }
