import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../core';

@Injectable()
export class PageApiService extends ApiService {
  constructor(protected override readonly httpClient: HttpClient) {
    super(httpClient, "/api/pages");
  }
}
