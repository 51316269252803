import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentHdCarouselComponent } from './component-hd-carousel.component';
import { CarouselModule } from 'primeng/carousel';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PipeModule } from 'src/app/pipe/pipe.module';

@NgModule({
  declarations: [
    ComponentHdCarouselComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    ButtonModule,
    PipeModule
  ],
  exports:[
    ComponentHdCarouselComponent
  ]
})
export class ComponentHdCarouselModule { }
