import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutPagePublicComponent } from './layout-page-public.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingService, LoadingInterceptor } from 'ngx-techsured';

@NgModule({
  declarations: [
    LayoutPagePublicComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LayoutPagePublicComponent
  ],providers:[
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }   
  ]
})
export class LayoutPagePublicModule { }
