<main class="bg-primary px-2 py-4 px-sm-4 min-vh-100 d-flex flex-column align-items-center text-center">

    <a (click)="onCallMeBackClick()" role="button" class="text-white fs-7 d-inline-flex text-decoration-none align-items-center ms-lg-auto">
      Need help? <span class="ms-2 fw-bold">Call me back</span> <i class="pi pi-fw pi-angle-right fs-6"></i>
    </a>
  
    <a role="button" [routerLink]="['/home']" class="mt-4 mt-lg-0">
      <img srcset="assets/techsured_logo.png, assets/techsured_logo@2x.png 2x" src="assets/techsured_logo.png" alt="Techsured Logo" width="110" height="114">
    </a>
  
    <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center gap-4 mt-5 mb-2">
      <h2 class="fs-2d fw-bold text-white mb-0"><span class="text-secondary">Welcome back,</span> John!</h2>
      <button pButton type="button" label="Logout" class="btn btn-sm btn-outline-secondary fw-normal fs-small py-1 pb-1 px-3" [routerLink]="['/home']"></button>
    </div>  
  
    <div class="container-lg my-4">
  
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10 col-xxl-9">
          <div class="d-flex flex-column gap-3 bg-dark-v2 p-4 rounded text-start mb-4">
            <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between gap-3">
              <h6 class="fw-normal text-white mb-0">My Cover</h6>
              <button type="button" pButton class="btn btn-sm btn-primary fs-7" label="Add Cover" [routerLink]="['/get-quote']"></button>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">            
              <div class="p-2 text-center text-md-start col-md-4">
                <h4 class="fw-bold text-white mb-0">BlackBerry 7100V</h4>
                <span class="fs-x-small text-white fw-bold">Serial: 123135134123</span>
              </div>
              <div class="me-lg-5 ms-xl-auto p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0">Techsured <span class="text-pink">Home</span></h4>
                <p class="fs-small mb-0 text-white opacity-80">Covered: Theft, Loss, Accidental Damage, Hotspotter</p>
              </div>
              <h3 class="fw-normal text-white mb-0 ms-md-auto me-md-4 p-2 text-md-end">R88pm</h3>
              <a role="button" tabindex="0" class="text-decoration-none align-self-end align-self-md-start order-first order-md-last" (click)="displayCancelModal = true"><i class="pi pi-times text-info opacity-62"></i></a>
            </div>
  
            <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
              <div class="p-2 text-center text-md-start col-md-4">
                <h4 class="fw-bold text-white mb-0">Samsung Galaxy P1 Tab</h4>
                <span class="fs-x-small text-white fw-bold">Serial: 123135134123</span>
              </div>
              <div class="me-lg-5 ms-xl-auto p-2 text-center text-md-start">
                <div class="d-flex flex-column flex-sm-row flex-wrap align-items-sm-center gap-2 gap-lg-3">
                  <h4 class="fw-bold text-info mb-0">Techsured Standard</h4>
                  <button pButton type="button" label="UPGRADE" class="btn btn-sm btn-primary fw-normal fs-small py-1 px-3" (click)="displayUpgradeModal = true"></button>
                </div>              
                <p class="fs-small mb-0 text-white opacity-80 mt-3 mt-sm-2 mt-lg-0">Covered: Theft, Loss, Accidental Damage</p>
              </div>
              <h3 class="fw-normal text-white mb-0 ms-md-auto me-md-4 p-2 text-md-end">R88pm</h3>
              <a role="button" tabindex="0" class="text-decoration-none align-self-end align-self-md-start order-first order-md-last" (click)="displayCancelModal = true"><i class="pi pi-times text-info opacity-62"></i></a>
            </div>
          </div>
          <div class="row mb-4 g-4">
            <div class="col-12 col-md-6">
              <div class="bg-dark-v2 p-4 rounded text-start h-100">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <h6 class="fw-normal text-white mb-0">Personal Information</h6>
                  <button pButton type="button" label="Edit" class="btn btn-sm btn-outline-secondary fw-normal fs-small py-1 px-3" (click)="capturePersonalInfromation()"></button>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Name:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">Mr John Doe</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">ID Number:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">9105025145085</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Mobile:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">076 969 2245</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Email:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">john@email.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="bg-dark-v2 p-4 rounded text-start h-100">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <h6 class="fw-normal text-white mb-0">Billing Information</h6>
                  <button pButton type="button" label="Edit" class="btn btn-sm btn-outline-secondary fw-normal fs-small py-1 px-3" (click)="displayBillingEditModal = true"></button>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Bank:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">ABSA Bank</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Branch:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">1234567</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Acc Type:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">Savings</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Acc Num:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">1234567890</p>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4 col-lg-3">
                    <span class="fs-small text-info opacity-62">Debit Day:</span>
                  </div>
                  <div class="col-8 col-lg-9">
                    <p class="text-white mb-0">25th</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column gap-3 bg-dark-v2 p-4 rounded text-start">
            <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between gap-3">
              <h6 class="fw-normal text-white mb-0">My Claims</h6>
              <button type="button" pButton class="btn btn-sm btn-outline-primary fs-7" label="Submit Claim" (click)="captureClaim()"></button>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
              <div class="p-2 text-center text-md-start col-md-3">
                <h4 class="fw-bolder mb-0 text-secondary">#124124</h4>
                <span class="fw-bold fs-x-small text-white">24 JUNE 2022</span>
              </div>
              <div class="p-2 text-center text-md-start">
                <h4 class="fw-bold text-white mb-0">BlackBerry 7100V</h4>
                <span class="fs-x-small text-white fw-bold">Serial: 123135134123</span>
              </div>
              <div class="p-2 bg-danger bg-opacity-50 ms-md-auto rounded me-md-2 my-2 my-md-0">
                <h6 class="text-white fw-bold mb-0 mx-3">PROCESSING</h6>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
              <div class="p-2 text-center text-md-start col-md-3">
                <h4 class="fw-bolder mb-0 text-secondary">#124124</h4>
                <span class="fw-bold fs-x-small text-white">24 JUNE 2022</span>
              </div>
              <div class="p-2 text-center text-md-start">
                <h4 class="fw-bold text-white mb-0">Samsung Galaxy P1 Tab</h4>
                <span class="fs-x-small text-white fw-bold">Serial: 123135134123</span>
              </div>
              <div class="p-2 bg-success bg-opacity-50 ms-md-auto rounded me-md-2 my-2 my-md-0">
                <h6 class="text-white fw-bold mb-0 mx-3">COMPLETED</h6>
              </div>
            </div>
          </div>       
        </div>
      </div>
    </div>
  
    <footer class="bg-primary mt-auto w-100 pt-4 pt-md-5">
      <div class="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center gap-3 gap-sm-4">
        <span class="fs-x-small text-white opacity-62 me-sm-auto order-last order-sm-first">Copyright Techsured 2022</span>
    
        <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Privacy Policy</a>
        <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Terms & Conditions</a>
        <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">PAIA</a>
        <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Manual Disclosure</a>
      </div>
    </footer>
  </main>
  
  <!-- Modals -->
  <!-- <p-dialog [(visible)]="displayPersonalEditModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">Edit <span class="text-secondary">Profile</span></h2>
    </ng-template>
    <div class="px-4">
      <div class="row gx-3 gy-4">
        <div class="col-12 col-md-4">
          <p-dropdown [options]="titleOptions" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Title" optionLabel="option" [(ngModel)]="titleOptions[0]"></p-dropdown>
        </div>
        <div class="col-12 col-md-8">
          <input type="text" class="w-100 fs-7" placeholder="First Name" pInputText value="John" />
        </div>
        <div class="col-12">
          <input type="text" class="w-100 fs-7" placeholder="Surname" pInputText value="Doe" />
        </div>
        <div class="col-12">
          <input type="number" class="w-100 fs-7" placeholder="RSA ID Number" pInputText value="9105025145085" />
        </div>
        <div class="col-12">
          <input type="tel" class="w-100 fs-7" placeholder="Mobile Number" pInputText value="076 969 2245" />
        </div>
        <div class="col-12">
          <input type="email" class="w-100 fs-7" placeholder="Email address" pInputText  value="john@email.com" />
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Update" (click)="displayPersonalEditModal = false"></button>
      </div>
    </ng-template>
  </p-dialog>
   -->
  <p-dialog [(visible)]="displayBillingEditModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">Edit <span class="text-secondary">Billing Details</span></h2>
    </ng-template>
    <div class="px-4">
      <div class="row gx-3 gy-4">
        <div class="col-12">
          <p-dropdown [options]="bankOptions" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Bank" optionLabel="option" [(ngModel)]="bankOptions[0]"></p-dropdown>
        </div>
        <div class="col-12">
          <p-dropdown [options]="accountOptions" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Account Type" optionLabel="option" [(ngModel)]="accountOptions[0]"></p-dropdown>
        </div>
        <div class="col-12">
          <input type="number" class="w-100 fs-7" placeholder="Account Number" pInputText value="1234567890" />
        </div>
        <div class="col-12">
          <input type="number" class="w-100 fs-7" placeholder="Branch Code" pInputText value="1234567" />
        </div>
        <div class="col-12">
          <p-dropdown [options]="debitdayOptions" appendTo="body" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Debit Day" optionLabel="option" [(ngModel)]="debitdayOptions[1]"></p-dropdown>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Update" (click)="displayBillingEditModal = false"></button>
      </div>
    </ng-template>
  </p-dialog>
  
  <p-dialog [(visible)]="displayUpgradeModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3"><span class="text-secondary">Upgrade</span> your cover!</h2>
    </ng-template>
    <div class="px-4">
      <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
        <div class="p-2 text-center text-md-start mt-md-1">
          <h4 class="fw-bold text-white mb-0">BlackBerry 7100V</h4>
          <span class="fs-x-small text-white">Serial: 123135134123 • Techsured Standard</span>
        </div>
        <div class="p-2 text-center text-md-end ms-md-auto">
          <span class="fs-x-small fw-bold text-secondary">CURRENT</span>
          <h3 class="fw-normal text-white mb-0">R88pm</h3>
        </div>
      </div>
      <div class="text-center mt-4 mb-3">
        <h5 class="fw-bold text-white">Upgrade to...</h5>
      </div>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-xl-10 col-xxl-9">
          <div class="bg-blue box-shadow rounded-3 p-4">
            <div class="d-flex flex-column text-start gap-3 mt-3">
              <div class="d-flex align-items-center gap-3">
                <img src="assets/icons/icon-home-device.svg" alt="Shield with home icon" role="presentation" aria-hidden="true" width="36" height="44">
                <h3 class="fs-3b fw-bold text-info mb-0">Techsured <span class="text-pink">Home</span></h3>
              </div>            
              <div class="d-flex align-items-center gap-3">
                <img src="assets/icons/icon-check-thin.svg" alt="Check icon with circle" role="presentation" aria-hidden="true" width="19" height="19">
                <h6 class="fw-normal mb-0 text-white opacity-80">Theft</h6>
              </div>
              <div class="d-flex align-items-center gap-3">
                <img src="assets/icons/icon-check-thin.svg" alt="Check icon with circle" role="presentation" aria-hidden="true" width="19" height="19">
                <h6 class="fw-normal mb-0 text-white opacity-80">Loss</h6>
              </div>
              <div class="d-flex align-items-center gap-3">
                <img src="assets/icons/icon-check-thin.svg" alt="Check icon with circle" role="presentation" aria-hidden="true" width="19" height="19">
                <h6 class="fw-normal mb-0 text-white opacity-80">Accidental Damage</h6>
              </div>
      
              <button pButton class="btn btn-primary px-4 mt-4" label="Upgrade for R105pm" (click)="displayUpgradeModal = false; displayUpgradeSuccessModal = true;"></button>
            </div>
          </div>
        </div>
      </div>
  
      <p class="fs-small text-info opacity-62 text-center">Your new cover will start on 31 July 2022. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
  </p-dialog>
  
  <p-dialog [(visible)]="displayUpgradeSuccessModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3"><span class="text-secondary">Thanks</span> for Upgrading!</h2>
    </ng-template>
    <div class="px-4">
      <div class="text-center mb-4">
        <img src="assets/icons/icon-check.svg" alt="Check icon in red circle" role="presentation" aria-hidden="true" width="109" height="109" class="img-fluid">
      </div>
      <p class="fs-small text-info opacity-62 text-center mb-0">Your new cover will start on 31 July 2022. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
    <ng-template pTemplate="footer">
      <div class="pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Done" (click)="displayUpgradeSuccessModal = false"></button>
      </div>
    </ng-template>
  </p-dialog>
  
  <p-dialog [(visible)]="displayCancelModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3">Are you sure you want to <span class="text-secondary">cancel?</span></h2>
    </ng-template>
    <div class="px-4">
      <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
        <div class="p-2 text-center text-md-start">
          <h4 class="fw-bold text-white mb-0">BlackBerry 7100V</h4>
          <span class="fs-x-small text-white">Serial: 123135134123 • Techsured Standard</span>
        </div>
        <div class="p-2 text-center text-md-end ms-md-auto">
          <h3 class="fw-normal text-white mb-0">R88pm</h3>
        </div>
      </div>
  
      <p class="fs-small text-info opacity-62 text-center my-4">You will still be covered until 31 July 2022. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
  
      <div class="text-center">
        <p-checkbox name="confirmCheckbox" styleClass="blue-checkbox" labelStyleClass="text-start lh-base text-white fs-small" value="ConfirmYes" label="I confirm that my cover will stop on 31 July 2022" [(ngModel)]="confirmCheckbox"></p-checkbox>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-center align-items-sm-center px-4 px-sm-0 gap-3 pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-outline-secondary px-5" label="Back" (click)="displayCancelModal = false;"></button>
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Done" (click)="displayCancelModal = false; displayCancelSuccessModal = true"></button>
      </div>
    </ng-template>
  </p-dialog>
  
  <p-dialog [(visible)]="displayCancelSuccessModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3">We're <span class="text-secondary">sad</span> to see you go...</h2>
    </ng-template>
    <div class="px-4">
      <div class="text-center mb-4">
        <img src="assets/icons/icon-check.svg" alt="Check icon in red circle" role="presentation" aria-hidden="true" width="109" height="109" class="img-fluid">
      </div>
      <p class="fs-small text-info opacity-62 text-center mb-0">You will still be covered until 31 July 2022. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
    <ng-template pTemplate="footer">
      <div class="pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Done" (click)="displayCancelSuccessModal = false"></button>
      </div>
    </ng-template>
  </p-dialog>
  
  <!-- <p-dialog [(visible)]="displaySubmitClaimModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3">Submit <span class="text-secondary">claim</span></h2>
    </ng-template>
    <div class="px-4">
      <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
        <div class="p-2 text-center text-md-start">
          <h4 class="fw-bold text-white mb-0">BlackBerry 7100V</h4>
          <span class="fs-x-small text-white">Serial: 123135134123 • Techsured Standard</span>
        </div>
      </div>
  
      <textarea pInputTextarea rows="5" placeholder="Describe your issue..." class="mt-4 w-100"></textarea> 
  
      <p-fileUpload name="uploadedFiles[]" styleClass="mt-3" [multiple]="true" chooseIcon="" chooseLabel="Supporting Images/Photos" [showCancelButton]="false" [showUploadButton]="false"></p-fileUpload>
  
      <p class="fs-small text-info opacity-62 text-center my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
  
      <div class="text-center">
        <p-checkbox name="confirmCheckbox" styleClass="blue-checkbox" labelStyleClass="text-start lh-base text-white fs-small" value="ConfirmYes" label="I confirm that I've given as much detail as possible" [(ngModel)]="confirmCheckbox"></p-checkbox>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-center align-items-sm-center px-4 px-sm-0 gap-3 pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-outline-secondary px-5" label="Cancel" (click)="displaySubmitClaimModal = false;"></button>
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Submit" (click)="displaySubmitClaimModal = false; displaySubmitClaimSuccessModal = true"></button>
      </div>
    </ng-template>
  </p-dialog> -->
  
  <p-dialog [(visible)]="displaySubmitClaimSuccessModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3"><span class="text-secondary">Thanks</span> for letting us know...</h2>
    </ng-template>
    <div class="px-4">
      <div class="text-center mb-4">
        <img src="assets/icons/icon-check.svg" alt="Check icon in red circle" role="presentation" aria-hidden="true" width="109" height="109" class="img-fluid">
      </div>
      <h4 class="text-white fw-bold text-center mt-2 mb-4">Claim reference: #23235</h4>
      <p class="fs-small text-info opacity-62 text-center mb-0">Your claim will be reviewed by our agents. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
    <ng-template pTemplate="footer">
      <div class="pb-4 mb-2">
        <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Done" (click)="displaySubmitClaimSuccessModal = false"></button>
      </div>
    </ng-template>
  </p-dialog>