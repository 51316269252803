import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentNavigationBarComponent } from './component-navigation-bar.component';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    ComponentNavigationBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenubarModule,
    AccordionModule,
    OverlayPanelModule,
    ButtonModule
  ],
  exports: [
    ComponentNavigationBarComponent
  ]
})
export class ComponentNavigationBarModule { }
