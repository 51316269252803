import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetFrequentlyAskedQuestionComponent } from './widget-frequently-asked-question.component';
import { AccordionModule } from 'primeng/accordion';
import { RouterModule } from '@angular/router';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ComponentStepGroupModule } from 'src/app/component/component-step-group/component-step-group.module';
import { ComponentListModule } from 'src/app/component/component-list/component-list.module';
import { ComponentInlineAlertModule } from 'src/app/component/component-inline-alert/component-inline-alert.module';

@NgModule({
  declarations: [
    WidgetFrequentlyAskedQuestionComponent
  ],
  imports: [
    CommonModule,
    AccordionModule,
    RouterModule,
    PipeModule,
    ComponentStepGroupModule,
    ComponentListModule,
    ComponentInlineAlertModule
  ],
  exports: [
    WidgetFrequentlyAskedQuestionComponent
  ]
})
export class WidgetFrequentlyAskedQuestionModule { }
