import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentStepGroupComponent } from './component-step-group.component';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ComponentImageModule } from '../component-image/component-image.module';

@NgModule({
  declarations: [
    ComponentStepGroupComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    ComponentImageModule
  ],
  exports: [
    ComponentStepGroupComponent
  ]
})
export class ComponentStepGroupModule { }
