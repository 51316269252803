import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutPagePublicSimpleComponent } from './layout-page-public-simple.component';
import {InputTextModule} from 'primeng/inputtext';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { DirectiveModule } from 'src/app/directive/directive.module';



@NgModule({
  declarations: [
    LayoutPagePublicSimpleComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    PipeModule,
    DirectiveModule
  ],
  exports: [
    LayoutPagePublicSimpleComponent
  ]
})
export class LayoutPagePublicSimpleModule { }
