<h2 class="fs-2d fw-bold text-white text-center mx-auto mt-3">Submit <span class="text-secondary">claim</span></h2>

<div class="px-4">
  <div class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
    <div class="p-2 text-center text-md-start">
      <h4 class="fw-bold text-white mb-0">BlackBerry 7100V</h4>
      <span class="fs-x-small text-white">Serial: 123135134123 • Techsured Standard</span>
    </div>
  </div>

  <textarea pInputTextarea rows="5" placeholder="Describe your issue..." class="mt-4 w-100"></textarea>

  <p-fileUpload name="uploadedFiles[]" styleClass="mt-3" [multiple]="true" chooseIcon=""
    chooseLabel="Supporting Images/Photos" [showCancelButton]="false" [showUploadButton]="false"></p-fileUpload>

  <p class="fs-small text-info opacity-62 text-center my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
    do eiusmod tempor incididunt ut.</p>

  <div class="text-center">
    <p-checkbox name="confirmCheckbox" styleClass="blue-checkbox"
      labelStyleClass="text-start lh-base text-white fs-small" value="ConfirmYes"
      label="I confirm that I've given as much detail as possible" [(ngModel)]="confirmCheckbox"></p-checkbox>
  </div>
</div>

<div
  class="d-flex flex-column flex-sm-row justify-content-sm-center align-items-sm-center px-4 px-sm-0 gap-3 pb-4 mb-2">
  <button type="button" pButton class="btn btn-sm btn-outline-secondary px-5" label="Cancel" (click)="close()"></button>
  <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Submit"></button>
</div>