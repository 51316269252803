import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo, gql } from "apollo-angular";
import { Observable, retry, shareReplay } from "rxjs";
import { GQLService } from "../../core";
import { GET_ARTICLES, GET_ARTICLE_BY_CATEGORY, GET_ARTICLE_BY_SLUG } from "./query/article-query.gql";

@Injectable({
  providedIn: "root"
})
export class ArticleGql extends GQLService{
  constructor(protected override apollo: Apollo) { 
    super(apollo);
  }

  findAllArticles(): Observable<ApolloQueryResult<any>> {
    return this.watchQuery(GET_ARTICLES)
    .valueChanges.pipe(retry({count:3,delay:3000}));
  }

  findBySlug(slug:string): Observable<ApolloQueryResult<any>>{
    return this.watchQuery(GET_ARTICLE_BY_SLUG,{slug:slug})
    .valueChanges.pipe(retry({count:3,delay:3000}));
  }

  findByCategorySlug(slug:string): Observable<ApolloQueryResult<any>>{
    return this.watchQuery(GET_ARTICLE_BY_CATEGORY,{slug:slug})
    .valueChanges.pipe(retry({count:3,delay:3000}));
  }

  
}