import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../core';

@Injectable()
export class EnergyLeadApiService extends ApiService {
    
    constructor(protected override readonly httpClient: HttpClient) {
        super(httpClient, "/api/vas");
    }

    createLead(_payload: any):Observable<any>{
        _payload['Company'] = 'Techsured',
        _payload['CustomInfo1'] = localStorage.getItem('utm_source'),
        _payload['CustomInfo2'] = localStorage.getItem('utm_medium'),
        _payload['CustomInfo3'] = localStorage.getItem('utm_campaign'),
        _payload['CustomInfo4'] = localStorage.getItem('slug')
        _payload['CustomInfo5'] = localStorage.getItem('utm_term')
        let payload = JSON.stringify(_payload)
        return this.post(`https://exergy-api.azure-api.net/leads/techsured`, payload,{
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        });
    }
    
    callMeBack(_payload: any): Observable<any>{
        _payload['Company'] = 'Techsured'
        _payload['CustomInfo1'] = localStorage.getItem('utm_source'),
        _payload['CustomInfo2'] = localStorage.getItem('utm_medium'),
        _payload['CustomInfo3'] = localStorage.getItem('utm_campaign'),
        _payload['CustomInfo4'] = localStorage.getItem('slug')
        _payload['CustomInfo5'] = localStorage.getItem('utm_term')
        let payload = JSON.stringify(_payload)
        return this.post(`https://exergy-api.azure-api.net/call/back`, payload, {
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        });
    }

    salesSource(_payload: any): Observable<any>{
        let payload = JSON.stringify(_payload)
        return this.post(`https://exergy-api.azure-api.net/sale/source`, payload, {
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        });
    }
}
