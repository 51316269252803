import { gql } from "@apollo/client/core";

export const GET_ALL_TESTIMONIES = gql`
query GetTestimonies {

    testimonies{
        data{
            attributes{
                CustomerFullName
                CustomerJobTitle
                Text
                CustomerAvatar{
                    data{
                        attributes{
                            url
                        }
                    }
                }
                CompanyLogo{
                                       data{
                        attributes{
                            url
                        }
                    } 
                }
            }
        }
    }

}`;