import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutPagePublicModule } from './layout-page-public/layout-page-public.module';
import { LayoutPageAccountModule } from './layout-page-account/layout-page-account.module';
import { LayoutPageAccountDashboardModule } from './layout-page-account-dashboard/layout-page-account-dashboard.module';
import { LayoutSectionCenteredModule } from './layout-section-centered/layout-section-centered.module';
import { LayoutPagePublicSimpleModule } from './layout-page-public-simple/layout-page-public-simple.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LayoutPagePublicModule,
    LayoutPageAccountModule,
    LayoutPageAccountDashboardModule,
    LayoutSectionCenteredModule,
    LayoutPagePublicSimpleModule
  ]
})
export class LayoutModule { }
