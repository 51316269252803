import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../core';

@Injectable()
export class SmtpToGOApiService extends ApiService {
    
    constructor(protected override readonly httpClient: HttpClient) {
        super(httpClient, "/api/vas");
    }

    sendMail(_body: any):Observable<any>{
        // these details will have to change
        const to_email = 'support@techsured.co.za'
        const from_email = 'support@trinityweb.co.za'

        let payload = {
        "Company": "Techsured",
        "api_key": "api-1365AF26654C11EAA936F23C91BBF4A0",  // TODO this should read from .env
        "to": [`Test Person <${to_email}>`],
        "sender": `Techsured Website Lead <${from_email}>`,
        "subject": "New Lead",
        "text_body": _body
        }
        let payload_json = JSON.stringify(payload)
        return this.post(`https://api.smtp2go.com/v3/email/send`, payload_json,{
            headers:{
                "content-type":"application/json; charset=utf-8"
            }
        });
    }
}
