import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageAccountRoutingModule } from './page-account-routing.module';
import { PageAccountComponent } from './page-account.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalSubmitClaimModule } from 'src/app/modal/modal-submit-claim/modal-submit-claim.module';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalPersonalInformationModule } from 'src/app/modal/modal-personal-information/modal-personal-information.module';
import { DirectiveModule } from 'src/app/directive/directive.module';

@NgModule({
  declarations: [
    PageAccountComponent
  ],
  imports: [
    CommonModule,
    PageAccountRoutingModule,
    DropdownModule,
    InputTextareaModule,
    CheckboxModule,
    DialogModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    ModalSubmitClaimModule,
    ModalPersonalInformationModule,
    DirectiveModule
  ],
  providers:[
    DialogService
  ]
})
export class PageAccountModule { }
