import { gql } from "@apollo/client/core";

export const GET_FREQUENTLY_ASKED_QUESTIONS = gql`
query GetFrequentlyAskedQuestions {
    frequentlyAskedQuestions{
        data{
            attributes{
                Title
                Text   
                Tags{
                    data{
                        attributes{
                            Value
                        }
                    }
                }
                Elements{
                    __typename
                    ... on ComponentElementHeader{
                        Identifier
                        Title
                        EmphasisWord
                        EmphasisColor
                        SubTitle
                    }     


                    ... on ComponentElementParagraph {
                        Identifier
                        Text
                    }      

                   ... on ComponentElementList{
                        Title
                        Items{
                            Text
                        }
                    }                                  
                }                             
            }
        }
    }
}
`;

export const GET_FREQUENTLY_ASKED_QUESTIONS_BY_TAGS = gql`
query GetFrequentlyAskedQuestionsByTags($tags:[String]!) {
    frequentlyAskedQuestions(filters:{Tags:{Value:{in:$tags}}}){
        data{
            attributes{
                Title
                Text
                Tags{
                    data{
                        attributes{
                            Value
                        }
                    }
                }
                Elements{
                    __typename
                    ... on ComponentElementHeader{
                        Identifier
                        Title
                        EmphasisWord
                        EmphasisColor
                        SubTitle
                    }     


                    ... on ComponentElementParagraph {
                        Identifier
                        Text
                    }      

                   ... on ComponentElementList{
                        Title
                        Items{
                            Text
                        }
                    }                                  
                }                       
            }
        }
    }
}
`;