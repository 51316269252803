import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentPageSectionComponent } from './component-page-section.component';



@NgModule({
  declarations: [
    ComponentPageSectionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ComponentPageSectionComponent
  ]
})
export class ComponentPageSectionModule { }
