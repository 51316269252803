import { gql } from "@apollo/client/core";

export const GET_ARTICLE_CATEGORY = gql`
query GetArticleCategory {
    articleCategories{
        data{
            attributes{
                Name
                Slug
                Parents{
                    data{
                        attributes{
                            Name
                            Slug
                        }
                    }
                }
            }
        }
    }
}

`;