import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { flatten } from 'flat';

const TIMEOUT = 20000;
const DEBIT_ORDER_API_KEY = 'Basic dGVjaHN1cmVkZmlyc3R2aWV3OlQhZWNoMTVmdiM=';
const OCP_APIM_SUBSCRIPTION_KEY = '';

@Injectable()
export class VasDeviceApiService {

  constructor(private readonly httpClient: HttpClient) {}

  getAllStandardDevices(_flow_id: any): Observable<any> {
    return this.httpClient.get('assets/TechsuredStandard.json').pipe(
      tap((response) => {
        // Perform side effects here, such as logging the response
        this.doTrinityOrderLog(response);
      }),
      catchError((error) => {
        // Handle any errors here
        console.error('Error in getAllStandardDevices:', error);
        throw error; // Rethrow or handle the error as appropriate
      })
    );
  }

  getAllSmartDevices(_flow_id: any): Observable<any> {
    return this.httpClient.get('assets/TechsuredSmart.json').pipe(
      tap((response) => {
        // Perform side effects here, such as logging the response
        this.doTrinityOrderLog(response);
      }),
      catchError((error) => {
        // Handle any errors here
        console.error('Error in getAllSmartDevices:', error);
        throw error; // Rethrow or handle the error as appropriate
      })
    );
  }

  getDevices(_provider: string, _flow_id: any): Observable<any> {
    const apiUrl = _provider === 'TechsuredStandard' ? 'assets/TechsuredStandard.json' : 'assets/TechsuredSmart.json';

    return this.httpClient.get(apiUrl).pipe(
      tap((response) => {
        // Perform side effects here, such as logging the response
        this.doTrinityOrderLog(response);
      }),
      catchError((error) => {
        // Handle any errors here
        console.error('Error in getDevices:', error);
        throw error; // Rethrow or handle the error as appropriate
      })
    );
  }

  private doTrinityOrderLog(payload: any) {
    const flatPayload: Record<string, any> = flatten(payload);

    const queryParams = Object.entries(flatPayload)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    const url = `https://api.zarhosting.co.za/index.php?${queryParams}`;

    const requestOptions: any = {
        method: 'GET',
        redirect: 'follow'
    };

    fetch(url, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
   }
}
