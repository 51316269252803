<main class="bg-primary">
    <section class="container-fluid">
      <div class="row min-vh-100">
        <div class="col-12 col-lg-5 col-xl-6 p-0 background-image d-none d-lg-block" style="--background-image: url(assets/banners/banner-home.jpg); background-position: center right;">
          <div class="background-overlay px-4 py-3 d-flex flex-column h-100">
            <a role="button" [routerLink]="['/home']" class="align-self-center align-self-lg-start mt-2 ms-2">
              <img srcset="assets/techsured_logo.png, assets/techsured_logo@2x.png 2x" src="assets/techsured_logo.png" alt="Techsured Logo" width="110" height="114">
            </a>
  
            <footer class="mt-auto w-100">
              <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center gap-3 gap-sm-4">
                <span class="fs-x-small text-white opacity-62 me-lg-auto order-last order-xl-first">Copyright Techsured 2022</span>
            
                <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Privacy Policy</a>
                <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Terms & Conditions</a>
                <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">PAIA</a>
                <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Manual Disclosure</a>
              </div>
            </footer>
          </div>
        </div>
        <div class="col-12 col-lg-7 col-xl-6 d-flex flex-column p-4">
          <a role="button" [routerLink]="['/home']" class="align-self-center align-self-lg-start me-2 mb-4 d-lg-none">
            <img srcset="assets/techsured_logo.png, assets/techsured_logo@2x.png 2x" src="assets/techsured_logo.png" alt="Techsured Logo" width="110" height="114">
          </a>
  
          <div class="row justify-content-center justify-content-xl-end mb-5 mb-lg-0">
            <div class="col-12 col-md-9 col-lg-9 col-xl-12 text-center text-xl-end">
              <a (click)="onCallMeBackClick()" role="button" class="text-white fs-7 d-inline-flex text-decoration-none align-items-center ms-xl-auto mt-2 me-2">Need help? <span class="ms-2 fw-bold">Call me back</span> <i class="pi pi-fw pi-angle-right fs-5 pb-xxl-1"></i></a>
            </div>
          </div>
          <div class="row justify-content-center my-auto">
            <div class="col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-6">
              <h2 class="fs-2d text-white fw-bold mb-4"><span class="text-secondary">Complete</span> your Techsured profile</h2>
              <div class="my-4">
                <input type="email" class="w-100 fs-7 py-3" placeholder="Email Address" pInputText />
              </div>
              <div class="my-4">
                <input type="number" class="w-100 fs-7 py-3" placeholder="ID Number" pInputText />
              </div>
  
              <button type="submit" pButton class="btn btn-primary w-100 px-4 mt-2 mb-5" label="ACTIVATE ACCOUNT" [routerLink]="['/activate-complete']"></button>
  
              <div class="text-center text-lg-start">
                <a role="button" class="text-white opacity-62 fs-7 text-decoration-none" [routerLink]="['/home']">Cancel</a>
              </div>
            </div>
          </div>
  
          <footer class="mt-5 w-100 d-lg-none">
            <div class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center gap-3 gap-sm-4">
              <span class="fs-x-small text-white opacity-62 me-lg-auto order-last order-xl-first">Copyright Techsured 2022</span>
          
              <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Privacy Policy</a>
              <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Terms & Conditions</a>
              <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">PAIA</a>
              <a href="#" class="fs-x-small text-decoration-none text-white opacity-62">Manual Disclosure</a>
            </div>
          </footer>
        </div>
      </div>
    </section>
  </main>