import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-personal-information',
  templateUrl: './modal-personal-information.component.html',
  styleUrls: ['./modal-personal-information.component.scss']
})
export class ModalPersonalInformationComponent implements OnInit {

  callResponseMsg = {
    show: false,
    message: '',
  }

  // titleOptions = [
  //   { option: 'Mr', id: 1},
  //   { option: 'Dr', id: 2 },
  //   { option: 'Mrs', id: 3 },
  //   { option: 'Miss', id: 4 },
  //   { option: 'Other', id: -1 }
  // ];

  // selectedTitle : object = {};

  personalInfo: any = {
    EmailAddress: "",
    FirstName: "",
    IdNumber: "",
    LastName: "",
    MSISDN: "",
    Title: {option: 'N/A', id: 0}
};

formValidities: any = {
  'FirstName': true,
  'LastName': true,
  'IdNumber': true,
  'EmailAddress': true,
  'MSISDN': true,
  'device': true
};


  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private _formBuilder: FormBuilder
  ) { }

  isValidForm(){
    if(this.personalInfo['FirstName'].length < 5){
      this.callResponseMsg['show'] = true
      this.callResponseMsg['message'] = 'Please Provide A Longer Name'   
      this.formValidities['FirstName']=false;
      return false
    }
    else{this.formValidities['FirstName']=true;}

    if(this.personalInfo['LastName'].length < 1){
      this.callResponseMsg['show'] = true
      this.callResponseMsg['message'] = 'Please Provide A Longer Last Name'   
      this.formValidities['LastName']=false;return false}
    else{this.formValidities['LastName']=true;}

    let validContactNumber = this.isValidCellNumber(this.personalInfo['MSISDN'])
    if(!validContactNumber){
      this.callResponseMsg['show'] = true
      this.callResponseMsg['message'] = 'Please Provide A Valid Contact Number'   
      this.formValidities['MSISDN']=false;return false}
    else{this.formValidities['MSISDN']=true}

    let validEmail = this.isValidEmail(this.personalInfo['EmailAddress'])
    if(!validEmail){
      this.callResponseMsg['show'] = true
      this.callResponseMsg['message'] = 'Please Provide A Valid Email'   
      this.formValidities['EmailAddress']=false;return false}
    else{this.formValidities['EmailAddress']=true}

    return true;
  }

  isValidEmail(_email: string): boolean{
    let regex=/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/;
    return regex.test(_email)
};

isValidCellNumber(_number: string): boolean{
    if(_number.length == 10){
        return true;
    }else{
        return false;
    }
};

validFormControl(_key: any){
  const value = this.formValidities[_key]
  if(value){
      return 'form-control'
  }else{
      return 'form-control is-invalid'
  }
}

  ngOnInit(): void {
    let data = this.dynamicDialogConfig.data;
    this.personalInfo = data;
    // this.selectedTitle = data.Title
  }

  close() {
    const valid = this.isValidForm()
        if(!valid){
          this.callResponseMsg['show'] = true
          return;
        }
    this.callResponseMsg['show'] = false
    // this.personalInfo.Title = this.selectedTitle
    this.dynamicDialogRef.close(this.personalInfo);
  }
}
