import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { PLATFORM_ID, Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(@Inject(PLATFORM_ID) public _platformId: string) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this._platformId);
  }

  get isServer(): boolean {
    return isPlatformServer(this._platformId);
  }
}

