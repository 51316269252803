import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo, gql } from "apollo-angular";
import { Observable, retry, shareReplay } from "rxjs";
import { GQLService } from "../../core";
import { GET_ALL_DOWNLODABLES } from "./query/downloadable.query";

@Injectable({
  providedIn: "root"
})
export class DownloadableGql extends GQLService {
  constructor(protected override apollo: Apollo) {
    super(apollo);
  }

  findAll(): Observable<ApolloQueryResult<any>> {
    return this.watchQuery(GET_ALL_DOWNLODABLES).valueChanges.pipe(retry({count:3,delay:3000}));
  }

}