import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentImageComponent } from './component-image.component';
import { PipeModule } from 'src/app/pipe/pipe.module';

@NgModule({
  declarations: [
    ComponentImageComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    ComponentImageComponent
  ]
})
export class ComponentImageModule { }
