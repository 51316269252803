import { Component, OnInit } from '@angular/core';
import { Page } from 'ngx-techsured';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalPersonalInformationComponent } from 'src/app/modal/modal-personal-information/modal-personal-information.component';
import { ModalSubmitClaimComponent } from 'src/app/modal/modal-submit-claim/modal-submit-claim.component';
import { ModalCallMeBackComponent } from "src/app/modal/modal-call-me-back/modal-call-me-back.component";

@Component({
  selector: 'app-page-account',
  templateUrl: './page-account.component.html',
  styleUrls: ['./page-account.component.scss']
})
export class PageAccountComponent extends Page implements OnInit {

  // Checkbox
  confirmCheckbox!: boolean;

  // Modals
  displayPersonalEditModal: boolean = false;
  displayBillingEditModal: boolean = false;

  displayUpgradeModal: boolean = false;
  displayUpgradeSuccessModal: boolean = false;

  displayCancelModal: boolean = false;
  displayCancelSuccessModal: boolean = false;

  displaySubmitClaimModal: boolean = false;
  displaySubmitClaimSuccessModal: boolean = false;

  // Upload files
  uploadedFiles: any[] = [];

  // Help options dropdown
  // titleOptions = [
  //   { option: 'Mr' },
  //   { option: 'Mrs' },
  //   { option: 'Miss' },
  //   { option: 'Other' }
  // ]

  // Bank dropdown
  bankOptions = [
    { option: 'ABSA' },
    { option: 'Nedbank' },
    { option: 'Capitec' },
    { option: 'Standard Bank' },
    { option: 'FNB/RMB' },
    { option: 'Discovery Bank' },
    { option: 'Tymebank' }
  ]

  // Account type dropdown
  accountOptions = [
    { option: 'Savings' },
    { option: 'Cheque' },
    { option: 'Current' },
    { option: 'Business' }
  ]

  // Debit Day dropdown
  debitdayOptions = [
    { option: 'First of the Month' },
    { option: '25th' },
    { option: '26th' },
    { option: 'Last day of the Month' }
  ]

  constructor(private readonly _dialogService: DialogService) {
    super({ dialogService: _dialogService });
  }

  ngOnInit(): void { }

  captureClaim() {
    this.openModal(ModalSubmitClaimComponent, {
      data: {
        id: '51gF3'
      },
      styleClass: "modal-bg-dark modal-sm",
      modal: true
    });
  }

  capturePersonalInfromation() {
    this.openModal(ModalPersonalInformationComponent, {
      data: {
        id: '51gF3'
      },
      styleClass: "modal-bg-dark modal-sm",
      modal: true
    });
  }

  onCallMeBackClick(){
    const modalInst = this.openModal(ModalCallMeBackComponent, {
      data: {},
      styleClass: "modal-bg-dark modal-sm",
      modal: true
  })
  }
}
