import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { CarouselModule } from 'primeng/carousel';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { WidgetTestimonyCarouselComponent } from './widget-testimony-carousel.component';

@NgModule({
  declarations: [
    WidgetTestimonyCarouselComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    CarouselModule,
    AvatarGroupModule
  ],
  exports: [
    WidgetTestimonyCarouselComponent
  ]
})
export class WidgetTestimonyCarouselModule { }
