import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from './app.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    protected readonly route: ActivatedRoute, 
    private appService: AppService
  ) { }

  get isServer(): boolean {
    return this.appService.isServer;
  }

  get isBrowser(): boolean {
    return this.appService.isBrowser;
  }

  decodeBase64(base64String: string): string {
    return atob(base64String);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.appService._platformId)) {
      let paramsToStore: Record<string, any> = {};

      this.route.paramMap.subscribe(params => {
        const slug = params.get('slug');
        if (slug) {
          paramsToStore['slug'] = slug;
          localStorage.setItem('slug', slug);  // Store individual slug
        }
      });

      this.route.queryParams.subscribe(queryParams => {
        const expectedParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'ud'];
        expectedParams.forEach(param => {
          if (queryParams[param]) {
            paramsToStore[param] = queryParams[param];
            localStorage.setItem(param, queryParams[param]);  // Store each parameter individually
          }
        });

        if (Object.keys(paramsToStore).length > 0) {
          this.storeParams(paramsToStore);
        }
      });
    }
  }

  storeParams(params: Record<string, any>) {
    localStorage.setItem('queryParams', JSON.stringify(params));  // Store all params as one JSON string
  }

}
