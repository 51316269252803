import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-page-account',
  templateUrl: './layout-page-account.component.html',
  styleUrls: ['./layout-page-account.component.scss']
})
export class LayoutPageAccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
