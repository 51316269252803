import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
 
  { path: 'landing/cell', loadChildren: () => import('./landing-page-landing/landing-page-landing.module').then(m => m.LandingPageLandingModule) },
  { path: 'landing/cell/:slug', loadChildren: () => import('./landing-page-landing/landing-page-landing.module').then(m => m.LandingPageLandingModule) },
  // { path: 'lp/:slug', pathMatch:"full", loadChildren: () => import('./landing-page-landing/landing-page-landing.module').then(m => m.LandingPageLandingModule) },
  { path: 'landing/:slug/:provider', pathMatch:"full", loadChildren: () => import('./landing-page-landing/landing-page-landing.module').then(m => m.LandingPageLandingModule) },

];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }
