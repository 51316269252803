import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetFrequentlyAskedQuestionModule } from './widget-frequently-asked-question/widget-frequently-asked-question.module';
import { WidgetProductModule } from './widget-product/widget-product.module';
import { WidgetProductCardGroupModule } from './widget-product-card-group/widget-product-card-group.module';
import { WidgetArticleCarouselModule } from './widget-article-carousel/widget-article-carousel.module';
import { WidgetTestimonyCarouselModule } from './widget-testimony-carousel/widget-testimony-carousel.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WidgetFrequentlyAskedQuestionModule,
    WidgetProductModule,
    WidgetProductCardGroupModule,
    WidgetArticleCarouselModule,
    WidgetTestimonyCarouselModule
  ]
})
export class WidgetModule { }
