import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentAuthorBadgeComponent } from './component-author-badge.component';
import { AvatarModule } from 'primeng/avatar';
import { PipeModule } from 'src/app/pipe/pipe.module';

@NgModule({
  declarations: [
    ComponentAuthorBadgeComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    PipeModule
  ],
  exports: [
    ComponentAuthorBadgeComponent
  ]
})
export class ComponentAuthorBadgeModule { }
