import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentInlineAlertComponent } from './component-inline-alert.component';



@NgModule({
  declarations: [
    ComponentInlineAlertComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ComponentInlineAlertComponent
  ]
})
export class ComponentInlineAlertModule { }
