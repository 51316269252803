import { gql } from "@apollo/client/core";

export const GET_ALL_DOWNLODABLES = gql`
query GetDownloadable {

    downloadables{
data{
    attributes{
        identifier
        name
        asset{
            data{
                attributes{
                    url
                }
            }
        }
    }
}
               
    }


}`;