import { gql } from "@apollo/client/core";

export const GET_PAGE_AND_ELEMENTS_BY_SLUG = gql`
query GetPageAndElementsBySlug($slug: String!) {
  pages(filters:{Slug:{eq:$slug}}){
    data{
            attributes{
                Title
                Slug
                Path

                Elements{
                    __typename
                    ... on ComponentElementHeader{
                        Identifier
                        Title
                        EmphasisWord
                        EmphasisColor
                        SubTitle
                    }         

                    ... on ComponentElementHeroHeader{
                        Identifier
                        Title
                        SubTitle
                        Icon{
                            data{
                                attributes{
                                    url
                                }
                            }
                        }                        
                        BackgroundImage{
                            data{
                                attributes{
                                    url
                                }
                            }
                        }
                        Button{
                            Identifier
                            Label
                            Route
                            Tooltip
                        }
                    }

                    ... on ComponentElementIconGroup{
                        Identifier
                        Icons{
                            Identifier
                            Title
                            Text
                            Icon
                        }
                    }
                    ... on ComponentElementButton{
                        Identifier
                        Label
                        Route
                        Tooltip 
                    } 

                    ... on ComponentElementProductGrid{
                        Identifier
                        Products{
                            data{
                                attributes{
                                    Code
                                    Text
                                    Title
                                    Slug
                                    Images{
                                        data{
                                            attributes{
                                                url
                                            }
                                        }
                                    }
                                    Features{
                    Items{
                        Text
                        Title
                    }
                }                                      
                                }
                            }
                        }
                    }

                    ... on ComponentElementImageAsset{
                        Identifier
                        Alt
                        Items{
                            data{
                                attributes{
                                    url
                                    caption
                                    name
                                }
                            }
                        }
                    } 

                    ... on ComponentElementLink {
                        Identifier
                        Label
                        Route
                        Tooltip
                        SubLabel
                    }

                    ... on ComponentElementParagraph {
                        Identifier
                        Text
                    }

                    ... on ComponentElementParagraphGroup{
                            Identifier
                            Title
                            Paragraphs{
                                Identifier
                                Text
                            }
                    }                    

                    ... on ComponentElementProductCarousel{
                        Identifier
                        Products{
                            data{
                                attributes{
                                    Price
                                    Slug
                                    Text
                                    Title    
                                    Subtitle                                
                                    Images{
                                        data{
                                            attributes{
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ... on ComponentElementList{
                        ListItems: Items {
                            Text
                            Title
                        }
                    }


                    ... on ComponentSharedSeo{
                        canonicalURL
                        keywords
                        metaDescription
                        metaImage{
                            data{
                                attributes{
                                    url
                                    name
                                    alternativeText
                                }
                            }
                        }
                        metaRobots
                        metaSocial{
                            description
                            image{
                                data{
                                    attributes{
                                        url
                                        name
                                        alternativeText
                                    }
                                }
                            }
                            socialNetwork
                            title
                        }
                        metaTitle
                        metaViewport
                        structuredData
                    }        

                                        

                }
            }
        }        
  }
}

`;