import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeAttributePipe } from './pipe-attribute.pipe';
import { SafePipe } from './safe.pipe';
import { PipeFindPipe } from './pipe-find.pipe';

@NgModule({
  declarations: [
    PipeAttributePipe,
    PipeFindPipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ],exports:[
    PipeAttributePipe,
    PipeFindPipe,
    SafePipe
  ]
})
export class PipeModule { }
