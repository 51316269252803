import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-submit-claim',
  templateUrl: './modal-submit-claim.component.html',
  styleUrls: ['./modal-submit-claim.component.scss']
})
export class ModalSubmitClaimComponent implements OnInit {

  // Checkbox
  confirmCheckbox!: boolean;
  form: FormGroup | undefined;

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      item: [null, [Validators.required]],
      reason: [null, [Validators.required]],
      attachements: this._formBuilder.array([])
    });
  }

  close() {
    this.dynamicDialogRef.close();
  }
}
