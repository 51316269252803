import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class FormPrepopulateService {
  private utmParamsKey = 'queryParams';

  constructor(private fb: FormBuilder) {}

  prepopulateForm(encryptedData: string): FormGroup | null {
    if (encryptedData) {
      const decodedData = atob(encryptedData);
      const decryptedData = this.decryptData(decodedData);
      const formData = JSON.parse(decryptedData);

      const udValue = this.getDecodedUd();
      
      if (udValue) {
        // Use the decoded 'ud' value to prepopulate additional fields if needed
        formData.additionalField = udValue.additionalField;
      }

      return this.fb.group({
        FirstName: [formData.firstName],
        LastName: [formData.lastName],
        EmailAddress: [formData.email],
        MSISDN: [formData.mobileNumber],
        IdNumber: [formData.idNumber]
      });
    }

    return null;
  }

  private decryptData(data: string): string {
    const decrypted = CryptoJS.AES.decrypt(data, 'Someth1nGFun1!');
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public getDecodedUd(): any {
    const utmParamsString = localStorage.getItem(this.utmParamsKey);

    if (utmParamsString) {
      try {
        const utmParams = JSON.parse(utmParamsString);
        return this.decodeUd(utmParams.ud);
      } catch (error) {
        console.error('Error decoding ud:', error);
      }
    }

    return null;
  }

  private decodeUd(encodedUd: string): any {
    try {
      const decodedUd = atob(encodedUd);
      return JSON.parse(decodedUd);
    } catch (error) {
      console.error('Error decoding ud:', error);
      return null;
    }
  }
}
