import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDevicesListComponent } from './modal-devices-list.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';



@NgModule({
    declarations: [
        ModalDevicesListComponent
    ],
    imports: [
        CommonModule,
        DropdownModule,
        ReactiveFormsModule,
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        ButtonModule
    ],
    exports: [
        ModalDevicesListComponent
    ]
})
export class ModalDevicesListModule { }
