import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentIntellicellNavComponent } from './component-intellicell-nav.component';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    ComponentIntellicellNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenubarModule,
    AccordionModule,
    OverlayPanelModule,
    ButtonModule
  ],
  exports: [
    ComponentIntellicellNavComponent
  ]
})
export class ComponentIntellicellNavModule { }
