import { gql } from "@apollo/client/core";

export const GET_PRODUCT_BY_CATEGORY_SLUG = gql`
query GetProductByCategorySlug($categorySlug: String!) {

    
    products(filters:{ProductCategories:{Slug:{eq:$categorySlug}}}){
        data{
            attributes{
                Title
                Text
                Price
                Slug
                Code
                Images{
                    data{
                        attributes{
                            url
                        }
                    }
                }
                ProductCategories{

                    data{
                        attributes{
                            Slug
                            Title
                            Images{
                                data{
                                    attributes{
                                        url
                                    }
                                }
                            }
                        }
                    }
                }            
            }
        }
    
}

}
`;

export const GET_PRODUCT_BY_SLUG = gql`
query GetProductBySlug($slug: String!) {
    
    products(filters:{Slug:{eq:$slug}}){
        data{
            attributes{
                Title
                Text
                Price
                Code
                Images{
                    data{
                        attributes{
                            url
                        }
                    }
                }
                ProductCategories{

                    data{
                        attributes{
                            Slug
                            Title
                            Images{
                                data{
                                    attributes{
                                        url
                                    }
                                }
                            }
                        }
                    }
                }            
            }
        }
    
}

}

`;


