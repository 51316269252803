import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentProductGridComponent } from './component-product-grid.component';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ButtonModule } from 'primeng/button';
import { ComponentProductCardModule } from '../component-product-card/component-product-card.module';



@NgModule({
  declarations: [
    ComponentProductGridComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DirectiveModule,
    PipeModule,
    ButtonModule,
    ComponentProductCardModule
  ],
  exports:[
    ComponentProductGridComponent
  ]
})
export class ComponentProductGridModule { }
